import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
gsap.registerPlugin(ScrollToPlugin)

export default class Anchors {
  constructor(container) {
    this.DOM = { container: container }
    this.DOM.anchorsLinks = this.DOM.container.querySelectorAll('[data-anchor]')

    if (!this.anchorsLinks && !this.DOM.anchorsLinks.length) return

    this.addEvents()
  }

  addEvents() {
    const { anchorsLinks } = this.DOM

    this.onAnchorClick = this.onAnchorClick.bind(this)

    anchorsLinks.forEach((item) => item.addEventListener('click', this.onAnchorClick))
  }

  onAnchorClick(e) {
    e && e.preventDefault()

    const { currentTarget } = e

    const url = currentTarget.getAttribute('href')
    const anchor = `#${url.match(/#(.*)/)[1] }`

    gsap.to(window, { scrollTo: anchor, overwrite: true, duration: 1.8, ease: 'expo.inOut' })
  }

  destroy() {
    const { anchorsLinks = null } = this.DOM

    anchorsLinks && anchorsLinks.length && anchorsLinks.forEach((item) => item.addEventListener('click', this.onAnchorClick))
  }
}
