import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export default class LandingHero {
  constructor(container) {
    if (!container) return

    this.DOM = { container }
    this.DOM.heroContainer = this.DOM.container.querySelector(':scope > .container')

    if (!this.DOM.heroContainer) return

    // MatchMedia variable
    this.mm = gsap.matchMedia()
    // Breakpoint
    this.breakpoint = 768
    // Responsive object
    this.responsiveObj = {
      isDesktop: `(min-width: ${this.breakpoint}px)`,
      isMobile: `(max-width: ${this.breakpoint - 1}px)`
    }

    this.setParallax()
  }

  setParallax() {
    const { container, heroContainer } = this.DOM

    this.mm.add(this.responsiveObj, (context) => {
      const { isDesktop } = context.conditions

      if (isDesktop) {
        this.tl = gsap
        .timeline({
          defaults: { ease: 'none' },
          scrollTrigger: {
            trigger: container,
            start: 'top top',
            end: 'bottom top',
            scrub: true
          }
        })
        .to(heroContainer, { scale: 0.85, yPercent: 25 }, 0)
      }
    })
  }

  destroy() {
    this.tl && this.tl.kill()
  }
}
