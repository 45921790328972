import { viewStorage, domStorage } from "../_globals"
import gsap from 'gsap'
import { Observer } from 'gsap/Observer'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from "gsap/ScrollToPlugin"
gsap.registerPlugin(Observer, ScrollTrigger, ScrollToPlugin)

export default class BackToTop {
  constructor() { 
    if (!domStorage.backToTop) return

    this.observer = Observer.create({
      target: window,
      type: 'scroll',
      onChange: (self) => {
        if (window.scrollY > 150 && !domStorage.body.classList.contains('--scrolled')) domStorage.body.classList.add('--scrolled')
        if (window.scrollY <= 150 && domStorage.body.classList.contains('--scrolled')) domStorage.body.classList.remove('--scrolled')
      }
    })

    this.elevator = this.elevator.bind(this)
    domStorage.backToTop.addEventListener('click', this.elevator)
  }

  elevator(e) {
    e && e.preventDefault()

    gsap.to(window, { scrollTo: 0, ease: 'power3.inOut', duration: 1.2 })
  }

  destroy() {
    if (!domStorage.backToTop) return

    this.observer && this.observer.kill()
    domStorage.backToTop.removeEventListener('click', this.elevator)
  }
}
