import { isDEVMODE, domStorage, viewStorage } from '../_globals'
import WokineForm from '@wokine-agency/frontend-validation/dist/WokineForm'
import FormValidator from '@wokine-agency/frontend-validation/dist/form-validator'
export default class NewsletterForm {
  constructor(formSelector) {
    this.DOM = {
      form: formSelector
    }

    this.DOM.inputs = this.DOM.form.querySelectorAll('input, textarea')
    this.DOM.fields = this.DOM.form.querySelectorAll('input, select, textarea')
    this.DOM.resultText = this.DOM.form.querySelector('.Form__output p')
    this.DOM.submitBtn = this.DOM.form.querySelector('button[type=submit]')

    this.formURL = this.DOM.form.dataset.ajaxurl
    const validator = new FormValidator(formSelector, [
      { field: 'newsletter_email', rules: { required: true, email: true } },
    ], [
      { field: 'newsletter_email', required: 'L\'email est requis', email: 'L\'email doit être valide' },
    ], isDEVMODE)
    this.newsletterForm = new WokineForm(formSelector, validator, this.onSubmit.bind(this))
    this.init()
  }

  init() {
    if (isDEVMODE) console.log('Init NewsletterForm')
  }

  addEvents() {
    const { form, inputs } = this.DOM

    this.onSubmit = this.onSubmit.bind(this)
    form.addEventListener('submit', this.onSubmit)
  }

  onSubmit() {

    const { body } = domStorage
    const { viewScroll } = viewStorage
    const { fields, submitBtn } = this.DOM

    body.classList.add('--loading')

    submitBtn.disabled = true

    /* Remove previous errors */
    this.resetErrors()

    /* Add values to formData */
    const formData = this.getData()

    const xhr = new XMLHttpRequest()
    xhr.open('POST', this.formURL, true)
    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {

        switch (xhr.status) {
          case 200:
            this.onSuccess(xhr.responseText)
            break
          case 422:
            this.onInvalid(xhr.responseText)
            break
          default:
            this.onServerError()
        }

        submitBtn.disabled = false

        fields.forEach(field => {
          field.disabled = false
        })

        body.classList.remove('--loading')
        if (viewScroll) viewScroll.update()
      }
    }

    xhr.send(formData)
  }

  resetErrors() {
    const { form, resultText } = this.DOM

    resultText.innerHTML = ''
    resultText.classList.remove('--error')

    const errors = form.querySelectorAll('span.--error')
    errors.forEach((err) => {
      err.parentNode.classList.remove('--error')
      err.parentNode.removeChild(err)
    })

  }

  getData() {
    const { fields } = this.DOM

    const formData = new FormData()
    fields.forEach(field => {
      field.disabled = true
      if (field.type === 'radio') {
        if (field.checked) formData.append(field.name, field.value)
      } else if (field.type === 'file') {
        if (field.multiple) {
          const ins = field.files.length;
          for (let i = 0; i < ins; i++) {
            formData.append(field.name[i], field.files[i]);
          }
        } else {
          formData.append(field.name, field.files[0]);
        }
      } else if (field.type === 'checkbox') {
        if (field.checked) {
          formData.append(field.name, field.value)
        }
      }
      else {
        formData.append(field.name, field.value)
      }
    })

    return formData
  }

  onSuccess(text) {
    const { form, resultText } = this.DOM

    resultText.innerHTML = text
    form.reset()
  }

  onInvalid(text) {
    // const { form } = this.DOM
    // const json = JSON.parse(text)
    //
    // for (const fieldName of Object.keys(json)) {
    //   const field = form.querySelector(`[name=${fieldName}]`)
    //   if (field) {
    //     const err = document.createElement('span')
    //     err.innerHTML = json[fieldName].join('. ')
    //     err.classList.add('--error')
    //     field.parentNode.appendChild(err)
    //     field.parentNode.classList.add('--error')
    //   }
    // }
  }

  onServerError() {
    const { resultText } = this.DOM

    resultText.innerHTML = 'Une erreur est survenue. Merci de réessayer ulterieurement'
    resultText.classList.add('--error')
  }

  destroy() {
    const { form } = this.DOM

    form.removeEventListener('submit', this.onSubmit)
  }
}
