import {Renderer} from '@unseenco/taxi'
import {isDEVMODE, viewStorage} from '../_globals'
import Anchors from '../modules/anchors'
import LandingHero from '../modules/landing-hero'
import LandingEvent from '../modules/landing-event'
import LandingDetails from '../modules/landing-details'
import LandingPro from '../modules/landing-pro'
import NewsletterForm from '../modules/NewsletterForm'
import WknSwiper from '../modules/wkn-swiper'
import WknPlyr from '../modules/wkn-plyr'

export default class Landing extends Renderer {
  initialLoad() {
    this.onEnter()
  }

  onEnter() {
    this.DOM = {
      heroSection: viewStorage.current.querySelector('.PageHeroHome'),
      eventSection: viewStorage.current.querySelector('.Event'),
      detailsSection: viewStorage.current.querySelector('.PresentationCards.--details'),
      proSection: viewStorage.current.querySelector('.PresentationCards.--pro'),
      newsletterForm: document.querySelector('.NewsletterForm'),
      swiperLogo: viewStorage.current.querySelectorAll('.Logo__gallery .swiper'),
      videoPlyr: viewStorage.current.querySelector('.Video__modal .plyr__container'),
      videoToggle: viewStorage.current.querySelector('.Video__toggle'),
      videoModal: viewStorage.current.querySelector('.Video__modal'),
      closeBtn: viewStorage.current.querySelector('.Video__modal .close')
    }
    this.init()
  }

  init() {
    const {
      heroSection = null,
      eventSection = null,
      detailsSection = null,
      proSection = null,
      newsletterForm = null,
      swiperLogo = null,
      videoToggle = null
    } = this.DOM

    if (heroSection) this.heroModule = new LandingHero(heroSection)
    if (eventSection) this.eventModule = new LandingEvent(eventSection)
    if (detailsSection) this.detailsModule = new LandingDetails(detailsSection)
    if (proSection) this.proModule = new LandingPro(proSection)

    if (newsletterForm) {
      this.contactFormModule = new NewsletterForm(newsletterForm)
    }

    this.anchors = new Anchors(viewStorage.current)

    if (videoToggle) {
      // Plyr Video Hero
      this.plyr = new WknPlyr(this.DOM.videoPlyr)
      this.DOM.videoToggle.addEventListener('click', () => {
        this.DOM.videoModal.classList.add('showModal')
        this.plyr.plyr.play()
      })

      this.DOM.closeBtn.addEventListener('click', () => {
        this.DOM.videoModal.classList.remove('showModal')
        this.plyr.plyr.stop()
      })
      // Plyr Video Hero
    }

    swiperLogo && this.initSwiperLogo()
  }

  initSwiperLogo() {
    const {swiperLogo} = this.DOM

    swiperLogo.forEach((swiper) => {
      swiper = new WknSwiper(swiper, {
        loop: true, centeredSlides: true, loopedSlides: 3, autoplay: {
          delay: 1,
        }, speed: 3000, slidesPerView: 'auto', allowTouchMove: false, spaceBetween: 56,
      })
    })
  }

  onLeaveCompleted() {
    this.heroModule && this.heroModule.destroy()
    this.eventModule && this.eventModule.destroy()
    this.detailsModule && this.detailsModule.destroy()
    this.proModule && this.proModule.destroy()
    this.anchors && this.anchors.destroy()
    this.swiperLogo && this.swiperLogo.destroy()
    this.plyr && this.plyr.destroy()
  }
}
