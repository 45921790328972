import { domStorage } from "../_globals"
import Form from './form'
import BackToTop from './back-to-top'

export default class Footer {
  constructor() {
    this.DOM = {  el: domStorage.footer }
    this.DOM.newsletterForm = this.DOM.el.querySelector('.NewsletterForm')
    this.DOM.backToTop = this.DOM.el.querySelector('.BackToTop svg')

    this.init()
  }

  init() {
    const { newsletterForm, backToTop = null } = this.DOM

    if(newsletterForm) this.newsletterFormModule = new Form(newsletterForm)
    if(backToTop) this.backToTop = new BackToTop(backToTop)

  }
}
