import './routing'

/*
Load Plugins / Functions
-------------------------------------------------- */
import { isDEVMODE, globalStorage } from './_globals'
import { onReady } from './_events'
import { onSniff } from './modules/sniffer'
import Header from './modules/header'
import Footer from './modules/footer'
import WknHelperGrid from './modules/wkn-helper-grid'
import LangSwitch from "./modules/lang-switch";

class App {
  constructor() {
    // Signature Wokine
    console.log('%cCreated by Wokine, with' + '%c ❤', 'color: #FFF padding: 8px 3px 8px 10px background:#000 border-radius:3px 0px 0px 3px', 'color: #71d1c2 padding: 8px 10px 8px 3px background:#000 border-radius: 0px 3px 3px 0px')
    console.log('%c⚡' + '%c wokine.com ' + '%c⚡', 'color: #000 padding: 8px padding-right: 2px background:#71d1c2 border-radius:3px 0px 0px 3px', 'color: #FFF padding: 8px 2px background:#71d1c2', 'color: #000 padding: 8px padding-left:2px background:#71d1c2 border-radius:0px 3px 3px 0px')

    this.init()
    this.addEvents()
  }

  init() {
    // Sniffer
    onSniff()

    // Create Header/Footer...
    globalStorage.Header = new Header()
    globalStorage.Footer = new Footer()
    globalStorage.LangSwitch = new LangSwitch()

    // Helper Grid
    if (isDEVMODE) globalStorage.WknHelperGrid = new WknHelperGrid()
  }

  addEvents() {
    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      onReady()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)
  }
}

window.app = new App()
