import { Renderer } from '@unseenco/taxi';
import { viewStorage } from '../_globals'
import ContactForm from "../modules/ContactForm";

export default class Contact extends Renderer {
  initialLoad() {
    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      this.onEnter()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)
  }

  onEnter() {
    this.DOM = {
      contactForm: viewStorage.current.querySelector('.ContactForm.Form'),
    }

    this.init()
  }

  init() {
    const {
      contactForm = null
    } = this.DOM

    if (contactForm) {
      this.contactFormModule = new ContactForm(contactForm)
    }
  }

  onLeaveCompleted() {
    this.contactFormModule && this.contactFormModule.destroy()
  }
}
