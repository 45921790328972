import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export default class LandingEvent {
  constructor(container) {
    if (!container) return

    this.DOM = { container }
    this.DOM.numbers = this.DOM.container.querySelector('.numbers')
    this.DOM.numberCards = this.DOM.container.querySelectorAll('.NumberCard')

    if (!this.DOM.numberCards && !this.DOM.numberCards.length) return

    this.setNumberCardsAppear()
  }

  setNumberCardsAppear() {
    const { numbers, numberCards } = this.DOM

    this.tl = gsap
      .timeline({
        defaults: { ease: 'none' },
        scrollTrigger: {
          trigger: numbers,
          start: 'top 80%',
          end: 'bottom top'
        }
      })
      .from(numberCards, { scale: 0.85, opacity: 0, stagger: 0.2, duration: 1, ease: 'elastic.out(1, 0.5)' }, 0)
  }

  destroy() {
    this.tl && this.tl.kill()
  }
}
