import {domStorage} from "../_globals";

export default class LangSwitch {

  constructor() {
    this.DOM = {
      el: domStorage.header.querySelector('.language')
    }
  }

  updateLinksFromPage(to) {
    const {el} = this.DOM
    const currentLinks = el.querySelectorAll('a')
    const newsLinks = to.page.querySelectorAll('.language a')

    if (newsLinks.length === 0) return

    currentLinks.forEach((link, i) => {
      link.href = newsLinks[i].href
    })
  }
}
