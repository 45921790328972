import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import WknPlyr from '../modules/wkn-plyr'
export default class Salon extends Renderer {

  initialLoad() {
    this.onEnter()
  }

  onEnter() {

    this.DOM = {
      videoPlyr : viewStorage.current.querySelector('.Video__modal .plyr__container'),
      videoToggle : viewStorage.current.querySelector('.Video__toggle'),
      videoModal : viewStorage.current.querySelector('.Video__modal'),
      closeBtn : viewStorage.current.querySelector('.Video__modal .close')
    }

    this.init()

  }

  init() {
    
    // if (isDEVMODE) console.log('Init Page : Default Page')
    // if (isDEVMODE) console.log(viewStorage.current)

    const {
      videoToggle = null
    } = this.DOM

    if (videoToggle) {
      this.plyr = new WknPlyr(this.DOM.videoPlyr)
      this.DOM.videoToggle.addEventListener('click', () => {
        this.DOM.videoModal.classList.add('showModal')
        this.plyr.plyr.play()
      })

      this.DOM.closeBtn.addEventListener('click', () => {
        this.DOM.videoModal.classList.remove('showModal')
        this.plyr.plyr.stop()
      })
    }
  }

  // onEnterCompleted() {}

  // onLeave() {}

  onLeaveCompleted() {
    this.plyr && this.plyr.destroy()
  }
}
