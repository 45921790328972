/*
    Load Plugins / Functions
-------------------------------------------------- */
import { Core } from '@unseenco/taxi'
import { globalStorage } from './_globals'
import { onEnter, onEnterCompleted, onLeave, onLeaveCompleted } from './_events'

/* --- Renderers --- */
import Page from './pages/page'
import Landing from './pages/landing'
import Partenaires from './pages/partenaires'
import Billetterie from './pages/billetterie'
import Salon from './pages/salon'
import Jumping from './pages/jumping'

/* --- Transitions --- */
import PageTransitionDefault from './transitions/page-transition-default'
import Contact from "./pages/contact";

/* --- Setup Core --- */
globalStorage.taxi = new Core({
  links: 'a:not([target]):not([href^=\\#]):not([data-taxi-ignore]):not(.ab-item):not(.wpml-ls-link)',
  renderers: {
    default: Page,
    landing: Landing,
    partenaires: Partenaires,
    billetterie: Billetterie,
    salon: Salon,
    jumping: Jumping,
    contact: Contact
  },
  transitions: {
    default: PageTransitionDefault
  }
})

/* --- Global Events --- */
globalStorage.taxi.on('NAVIGATE_IN', ({ to, trigger }) => onEnter(to, trigger))
globalStorage.taxi.on('NAVIGATE_OUT', ({ from, trigger }) => onLeave(from, trigger))
globalStorage.taxi.on('NAVIGATE_END', ({ to, from, trigger }) => onEnterCompleted(to, from, trigger))
