import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'

export default class DefaultPageRenderer extends Renderer {

  initialLoad() {
    this.onEnter()
  }

  onEnter() {

    this.init()

  }

  init() {
    if (isDEVMODE) console.log('Init Page : Default Page')
    // if (isDEVMODE) console.log(viewStorage.current)

  }

  // onEnterCompleted() {}

  // onLeave() {}

  // onLeaveCompleted() {}
}
