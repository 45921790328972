import { isDEVMODE, domStorage, globalStorage } from '../_globals'
import { Transition } from '@unseenco/taxi'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export default class PageTransitionDefault extends Transition {
  onEnter({ to, trigger, done }) {
    gsap  
      .timeline({
        onStart: () => {
          // Be sure Chrome reinit the scroll
          setTimeout(() => window.scrollTo(0, 0), 250)
        },
        onComplete: () => {
          // Update body classNames
          domStorage.body.classList.remove('--animating')

          // // Close Header
          // globalStorage.Header.close()
          
          // Refresh Scroll Triggers
          ScrollTrigger.refresh()
      
          // Display the page
          done()
        }
      })
      .set([ to, domStorage.footer ], { opacity: 1 }, 0)
  }

  onLeave({ from, trigger, done }) {
    gsap
      .timeline({
        // onStart: () => {},
        onComplete: () => {
          // Update body classNames
          domStorage.body.classList.add('--animating')
          domStorage.body.classList.remove('--scrolled')

          // Remove the page
          done()
        }
      })
      .set([ from, domStorage.footer ], { opacity: 0 }, 0)
  }
}
