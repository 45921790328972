import { isDEVMODE, domStorage, viewStorage } from '../_globals'
import WokineForm from '@wokine-agency/frontend-validation/dist/WokineForm'
import FormValidator from '@wokine-agency/frontend-validation/dist/form-validator'

export default class ContactForm {
  constructor(formSelector) {
    this.DOM = {
      form: formSelector
    }

    this.DOM.inputs = this.DOM.form.querySelectorAll('input, textarea')
    this.DOM.fields = this.DOM.form.querySelectorAll('input, select, textarea')
    this.DOM.resultText = this.DOM.form.querySelector('.Form__output p')
    this.DOM.submitBtn = this.DOM.form.querySelector('button[type=submit]')

    this.formURL = this.DOM.form.dataset.ajaxurl
    const validator = new FormValidator(formSelector, [
      { field: 'firstname', rules: {required: true } },
      { field: 'lastname', rules: { required: true} },
      { field: 'email', rules: { required: true, email: true } },
      { field: 'company', rules: { required: true, string: true} },
      { field: 'city', rules: { string: true} },
      { field: 'phone', rules: { required: true, string: true, regex: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/ } },
      { field: 'message', rules: { string: true} },
      { field: 'rgpd', rules: { required: true } }
    ], [
      { field: 'firstname', required: 'Le prénom est requis' },
      { field: 'lastname', required: 'Le nom est requis' },
      { field: 'email', required: 'L\'email est requis', email: 'L\'email doit être valide' },
      { field: 'company', required: 'La société est requise', string: 'La société doit être une chaîne de caractères' },
      { field: 'city', string: 'La ville doit être une chaîne de caractères' },
      { field: 'phone', required: 'Le téléphone est requis', string: 'Le téléphone doit être une chaîne de caractères', regex: 'Le téléphone doit être valide' },
      { field: 'message', string: 'Le message doit être une chaîne de caractères' },
      { field: 'rgpd', required: 'Vous devez accepter les conditions d\'utilisation' }
    ], isDEVMODE)
    this.contactForm = new WokineForm(formSelector, validator, this.onSubmit.bind(this))
    this.init()
  }

  init() {
    if (isDEVMODE) console.log('Init Form')

    this.addEvents()
  }

  addEvents() {
    const { form, inputs } = this.DOM

    this.onSubmit = this.onSubmit.bind(this)
    form.addEventListener('submit', this.onSubmit)
  }

  onSubmit(e) {
    e.preventDefault()

    const { body } = domStorage
    const { viewScroll } = viewStorage
    const { fields, submitBtn } = this.DOM

    body.classList.add('--loading')

    submitBtn.disabled = true

    /* Remove previous errors */
    this.resetErrors()

    /* Add values to formData */
    const formData = this.getData()

    const xhr = new XMLHttpRequest()
    xhr.open('POST', this.formURL, true)
    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {

        switch (xhr.status) {
          case 200:
            this.onSuccess(xhr.responseText)
            break
          case 422:
            this.onInvalid(xhr.responseText)
            break
          default:
            this.onServerError()
        }

        submitBtn.disabled = false

        fields.forEach(field => {
          field.disabled = false
        })

        body.classList.remove('--loading')
        if (viewScroll) viewScroll.update()
      }
    }

    xhr.send(formData)
  }

  resetErrors() {
    const { form, resultText } = this.DOM

    resultText.innerHTML = ''
    resultText.classList.remove('--error')

    const errors = form.querySelectorAll('span.--error')
    errors.forEach((err) => {
      err.parentNode.classList.remove('--error')
      err.parentNode.removeChild(err)
    })

  }

  getData() {
    const { fields } = this.DOM

    const formData = new FormData()
    fields.forEach(field => {
      field.disabled = true
      if (field.type === 'radio') {
        if (field.checked) formData.append(field.name, field.value)
      } else if (field.type === 'file') {
        if (field.multiple) {
          const ins = field.files.length;
          for (let i = 0; i < ins; i++) {
            formData.append(field.name[i], field.files[i]);
          }
        } else {
          formData.append(field.name, field.files[0]);
        }
      } else if (field.type === 'checkbox') {
        if (field.checked) {
          formData.append(field.name, field.value)
        }
      }
      else {
        formData.append(field.name, field.value)
      }
    })

    return formData
  }

  onSuccess(text) {
    const { form, resultText } = this.DOM

    resultText.innerHTML = text
    form.reset()
  }

  onInvalid(text) {
    // const { form } = this.DOM
    // const json = JSON.parse(text)
    //
    // for (const fieldName of Object.keys(json)) {
    //   const field = form.querySelector(`[name=${fieldName}]`)
    //   if (field) {
    //     const oldErrorsContainer = document.querySelector(`[data-error-for=${fieldName}]`)
    //     if (oldErrorsContainer) {
    //       oldErrorsContainer.innerHTML = ''
    //     }
    //     const err = document.createElement('span')
    //     err.innerHTML = json[fieldName].join('. ')
    //     err.classList.add('--error','laravel-error')
    //     field.parentNode.appendChild(err)
    //     field.parentNode.classList.add('--error')
    //   }
    // }
  }

  onServerError() {
    const { resultText } = this.DOM

    resultText.innerHTML = 'Une erreur est survenue. Merci de réessayer ulterieurement'
    resultText.classList.add('--error')
  }

  destroy() {
    const { form } = this.DOM

    form.removeEventListener('submit', this.onSubmit)
  }
}
