import {isDEVMODE, globalStorage, domStorage, viewStorage} from '../_globals'
import CountDown from "./countdown";
import { Observer } from 'gsap/Observer'

export default class Header {
  constructor() {
    this.DOM = {el: domStorage.header}
    this.DOM.headerContainer = this.DOM.el.querySelector('.container')
    this.DOM.brand = this.DOM.el.querySelector('.brand')
    this.DOM.toggle = this.DOM.el.querySelector('.NavToggle')
    this.DOM.menuContainer = this.DOM.el.querySelector('.Nav__container')
    this.DOM.countdown = document.querySelector('.countdown-event');

    this.DOM.days = document.querySelector('.days');
    this.DOM.hours = document.querySelector('.hours');
    this.DOM.minutes = document.querySelector('.minutes');
    this.DOM.seconds = document.querySelector('.seconds');

    this.DOM.test = document.querySelector('.test');


    this.DOM.message = document.querySelector('.message');
    this.DOM.heading = document.querySelector('h5');

    this.init()
    this.setEvents()
  }

  targetDate() {
    return new Date(`December 12 2024 09:00:00`)
  }

  init() {
    const {
      countdown,
      test,
      days,
      hours,
      minutes,
      seconds,
    } = this.DOM

    const format = (t) => {
      return t < 10 ? '0' + t : t;
    };

    const render = (time) => {

      days.innerHTML = `${format(time.days)}`
      hours.innerHTML = `: ${format(time.hours)}`
      minutes.innerHTML = `: ${format(time.minutes)}`
      seconds.innerHTML = `: ${format(time.seconds)}`
    };

    const showMessage = () => {
      const {message, heading} = this.DOM

      message.innerHTML = `C'est le grand jour !`;
      countdown.innerHTML = '';
      heading.style.display = 'none';
    };

    const hideMessage = () => {
      const {message, heading} = this.DOM

      message.innerHTML = '';
      heading.style.display = 'block';
    };

    const complete = () => {
      showMessage();

      setTimeout(() => {
        hideMessage();
        countdownTimer.setExpiredDate(this.targetDate());
      }, 1000 * 60 * 60 * 24);
    };

    const countdownTimer = new CountDown(
      this.targetDate(),
      render,
      complete
    );
  }

  setEvents() {
    const {overlay, body} = domStorage
    const {headerContainer, toggle} = this.DOM

    // Toggle
    toggle.addEventListener('click', (e) => {
      e.preventDefault()
      globalStorage.menuOpen === true ? this.close() : this.open()
    })

    globalStorage.openMobileMenu = () => this.open()
    globalStorage.closeMobileMenu = () => this.close()

    /**
     * Events
     */
    globalStorage.openMobileMenu = () => this.open()
    globalStorage.closeMobileMenu = () => this.close()

    /**
     * Resize event to close menu
     */
    this.resizeTimeout = setTimeout(() => {
    }, 0)
    this.onResize = this.onResize.bind(this)
    this.windowWidthResize = window.innerWidth
    window.addEventListener('resize', this.onResize)

    // Observers
    this.scrolledObserver = Observer.create({
      target: window,
      type: 'scroll',
      onChange: (self) => {
        if (window.scrollY > 50 && !body.classList.contains('--scrolled')) body.classList.add('--scrolled')
        if (window.scrollY <= 50 && body.classList.contains('--scrolled')) body.classList.remove('--scrolled')
      }
    })

    this.onScrollObserver = Observer.create({
      target: window,
      type: 'scroll',
      tolerance: 100,
      onUp: () => {
        if (!headerContainer.classList.contains('--visible')) headerContainer.classList.add('--visible')
      },
      onDown: () => {
        if (headerContainer.classList.contains('--visible')) headerContainer.classList.remove('--visible')
        if (globalStorage.submenuOpen) this.closeSubmenu()
      }
    })
  }

  open() {
    globalStorage.menuOpen = true
    domStorage.body.classList.add('--show-menu')
  }

  close() {
    globalStorage.menuOpen = false
    domStorage.body.classList.remove('--show-menu')
  }

  onResize() {
    clearTimeout(this.resizeTimeout)
    this.resizeTimeout = setTimeout(() => {
      if (this.windowWidthResize !== window.innerWidth) {
        this.windowWidthResize = window.innerWidth
        if (globalStorage.menuOpen === true) this.close()
      }
    }, 250)
  }
}
