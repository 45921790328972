import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export default class LandingPro {
  constructor(container) {
    if (!container) return

    this.DOM = { container }
    this.DOM.sectionContainer = this.DOM.container.querySelector(':scope > .container')
    this.DOM.cards = this.DOM.container.querySelectorAll('.PresentationCard')

    if (!this.DOM.sectionContainer) return

    // MatchMedia variable
    this.mm = gsap.matchMedia()
    // Breakpoint
    this.breakpoint = 768
    // Responsive object
    this.responsiveObj = {
      isDesktop: `(min-width: ${this.breakpoint}px)`,
      isMobile: `(max-width: ${this.breakpoint - 1}px)`
    }

    this.setParallax()
  }

  setParallax() {
    const { container, cards } = this.DOM

    this.mm.add(this.responsiveObj, (context) => {
      const { isDesktop } = context.conditions

      if (isDesktop) {
        this.tl = gsap
        .timeline({
          defaults: { ease: 'none' },
          scrollTrigger: {
            trigger: container,
            start: 'top bottom',
            end: 'bottom top',
            scrub: true
          }
        })
        .to(cards, { yPercent: (index) => index <= 0 ? '10' : '-10' }, 0)
      }
    })
  }

  destroy() {
    this.tl && this.tl.kill()
  }
}
