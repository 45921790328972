import { isDEVMODE, globalStorage, viewStorage, domStorage } from '../_globals'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)
import { pageIntro } from './page-intro'

export const GlobalIntro = () => {
  const { body, app } = domStorage

  gsap
    .timeline({
      // delay: 0.2,
      defaults: { duration: 1, ease: 'power3.inOut' },
      onStart: () => {
        body.classList.remove('--preloading')
        body.classList.remove('--loading')

        // Be sure Chrome reinit the scroll
        setTimeout(() => window.scrollTo(0, 0), 250)
      },
      onComplete: () => {
        ScrollTrigger.refresh()
        body.classList.remove('--animating')
        body.classList.remove('--intro')

        globalStorage.firstLoad = false
      }
    })
    .fromTo(app, { opacity: 0 }, { opacity: 1, duration: 0.5 }, 0)
    .add(pageIntro(), 0)
}
