import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import WknSwiper from '../modules/wkn-swiper'
import WknPlyr from '../modules/wkn-plyr'

export default class Partenaires extends Renderer {

  initialLoad() {

    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      this.onEnter()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)

  }

  onEnter() {
    this.DOM = {
      swiperLogo : viewStorage.current.querySelectorAll('.Logo__gallery .swiper'),
      videoPlyr : viewStorage.current.querySelector('.Video__modal .plyr__container'),
      videoToggle : viewStorage.current.querySelector('.Video__toggle'),
      videoModal : viewStorage.current.querySelector('.Video__modal'),
      closeBtn : viewStorage.current.querySelector('.Video__modal .close')
    }

    this.init()
  }

  init() {
    if (isDEVMODE) console.log(`Init Page : Partenaires`)
    if (isDEVMODE) console.log(viewStorage.current)

    const { swiperLogo } = this.DOM
    swiperLogo && this.initSwiperLogo()

    const {
      videoToggle = null
    } = this.DOM

    if (videoToggle) {
      this.plyr = new WknPlyr(this.DOM.videoPlyr)
      this.DOM.videoToggle.addEventListener('click', () => {
        this.DOM.videoModal.classList.add('showModal')
        this.plyr.plyr.play()
      })

      this.DOM.closeBtn.addEventListener('click', () => {
        this.DOM.videoModal.classList.remove('showModal')
        this.plyr.plyr.stop()
      })
    }
  }

  initSwiperLogo() {
    const { swiperLogo } = this.DOM

    swiperLogo.forEach((swiper) => {
      swiper = new WknSwiper(swiper, { 
        loop: true,
        loopFillGroupWithBlank: true,
        centerSlides : true,
        speed: 3000,
        slidesPerView:'auto',
        allowTouchMove: false,
        spaceBetween: 56,
      })
    })
  }

  // onEnterCompleted() {}

  // onLeave() {}

  onLeaveCompleted() {
    const { swiperLogo } = this
    swiperLogo && swiperLogo.destroy()

    this.plyr && this.plyr.destroy()
  }
}
