import { isDEVMODE, globalStorage, viewStorage, domStorage } from '../_globals'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { SplitText } from 'gsap/SplitText'
gsap.registerPlugin(ScrollTrigger, SplitText)

export const pageIntro = (delay = 0.35) => {
  const { body } = domStorage
  const { current } = viewStorage
  const currentPageDataset = current.dataset.taxiView

  // Only for Home
  if (currentPageDataset !== 'landing') return

  const pageHeroHome = viewStorage.current.querySelector('.PageHeroHome')
  const pageHeroHomeVisual = viewStorage.current.querySelector('.PageHeroHome .container > .visual__container')
  const pageHeroHomeInfos = viewStorage.current.querySelector('.PageHeroHome .container > .infos')
  const pageHeroHomeTitle = viewStorage.current.querySelector('.PageHeroHome .container > .head .title')
  const pageHeroHomeSubtitle = viewStorage.current.querySelector('.PageHeroHome .container > .head h2')
  const pageHeroHomeButtons = viewStorage.current.querySelectorAll('.PageHeroHome .container .btns .Btn')

  const tl = gsap
    .timeline({
      delay,
      defaults: { ease: 'power3.out', duration: 1 },
      onStart: () => {},
      onComplete: () => {
        // Cleaning styles
        if (pageHeroHomeTitle) gsap.set(pageHeroHomeTitle, { clear: 'all' })
        if (pageHeroHomeSubtitle) gsap.set(pageHeroHomeSubtitle, { clear: 'all' })
        if (pageHeroHomeInfos) gsap.set(pageHeroHomeInfos, { clear: 'all' })
        if (pageHeroHomeVisual) gsap.set(pageHeroHomeVisual, { clear: 'all' })
      }
    })
  
    // Home
    if (pageHeroHomeVisual) tl.from(pageHeroHomeVisual, { scale: 1.25, opacity: 0, ease: 'power3.out', duration: 1 }, 0)
    if (pageHeroHomeTitle) tl.from(pageHeroHomeTitle, { scale: 0.97, opacity: 0, duration: 1.5, ease: 'power3.inOut' }, 0.2)
    if (pageHeroHomeSubtitle) tl.from(pageHeroHomeSubtitle, { y: 20, opacity: 0, duration: 0.7, ease: 'power3.out' }, 0.3)
    if (pageHeroHomeInfos) tl.from(pageHeroHomeInfos, { y: -20, opacity: 0, duration: 0.7, ease: 'power3.out' }, 0.3)
    if (pageHeroHomeButtons && pageHeroHomeButtons.length) tl.from(pageHeroHomeButtons, { opacity: 0, y: 20, stagger: 0.05 }, 0.75)

  return tl
}
